 
 
import {   TransferEth } from "./components";
 

const DisplayPane: React.FC = () => { 
 

  return (
    <div >
      
     
      <div >
        
          <TransferEth />
         
      </div>
    </div>
  );
};

export default DisplayPane;
